import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from "styled-components"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import LayoutNew from "../components/layout-new"
import Seo from "../components/seo"

const StyledGatsbyImage = styled(GatsbyImage)`
  grid-area: 1/1;
  object-fit: cover;
  height: 400px;

  @media (min-width:1920px) {
    height: 600px;
  }

  @media (max-width:1023px) {
    height: 260px;
  }

  @media (width:1024px) {
    height: 300px;
  }

  @media (max-width:767px) {
    height: 125px;
  }
`

const StyledDiv = styled.div`
  .react-tabs {
    text-align: center;
  }

  .react-tabs__tab-list {
    margin: 0px;
    border-bottom: 1px solid #b02929;
  }

  .react-tabs__tab {    
    font-weight: bold;
    background: #403E42;
    color: #fff;
    font-size: 24px;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
    margin-right: 2px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #b02929;
  }
  .react-tabs__tab--selected,
  .react-tabs__tab:hover {
    background: #b02929;
    color: #fff;
  }
  .react-tabs__tab-panel {
    padding: 0;
    background: transparent;
  }

  @media (max-width:767px) {
    .react-tabs__tab-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .react-tabs__tab {
      margin-top: 6px;
      border-bottom: none;
    }

    .react-tabs__tab {
      width: 200px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`

const StyledPanelPlaceholder = styled.div`
  margin-bottom: 0px;
`

const pStyle = "text-lg text-left mb-6"

const PageTeamNew = () => {
  const data = useStaticQuery(graphql`
    query PageTeamNewQuery {
      prismicNewTeamPage {
        _previewable
        data {
          team_page_title
          show_page_title
          team_page_short_description
          team_page_hero_background {
            gatsbyImageData(
              width: 1920
            )
          }
          red_bubble_text
          team_bio_section {
            name
            position
            short_bio
            with_view_team_button
            team_category
            photo {
              alt
              gatsbyImageData(
                width: 400
              )
            }
          }
          team_bio_section_1 {
            name
            position
            short_bio
            photo {
              alt
              gatsbyImageData(
                width: 400
              )
            }
          }
          team_bio_section_2 {
            name
            position
            short_bio
            photo {
              alt
              gatsbyImageData(
                width: 400
              )
            }
          }
          team_bio_section_3 {
            name
            position
            short_bio
            photo {
              alt
              gatsbyImageData(
                width: 400
              )
            }
          }
          team_bio_section_4 {
            name
            position
            short_bio
            photo {
              alt
              gatsbyImageData(
                width: 400
              )
            }
          }
          team_bio_section_5 {
            name
            position
            short_bio
            photo {
              alt
              gatsbyImageData(
                width: 400
              )
            }
          }
        }
      }
      photoPlaceholder: file(relativePath: { eq: "team/team-photo-placeholder.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 400
          )
        }
      }
      imgHero: file(relativePath: { eq: "team/sra-team-cropped.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const doc = data.prismicNewTeamPage
  const imgHero = getImage(doc.data.team_page_hero_background)
  const imgSrc = getSrc(doc.data.team_page_hero_background)
  const photoPlaceholder = getImage(data.photoPlaceholder)

  // State to manage the active tab
  const [activeTab, setActiveTab] = useState(0);

  const handleButtonClickSales = ({ }) => {
    setActiveTab(0); // Assuming Marketing tab is the second tab (index 0)
    setTimeout(() => {
      const element = document.getElementById("Sales");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the delay as needed
  };

  const handleButtonClickMarketing = ({ }) => {
    setActiveTab(1); // Assuming Marketing tab is the second tab (index 1)
    setTimeout(() => {
      const element = document.getElementById("Marketing");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the delay as needed
  };
  
  const handleButtonClickOperations = ({ }) => {
    setActiveTab(2); // Assuming Marketing tab is the second tab (index 2)
    setTimeout(() => {
      const element = document.getElementById("Operations");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the delay as needed
  };

  const handleButtonClickFinanceHR = ({ }) => {
    setActiveTab(3); // Assuming Marketing tab is the second tab (index 3)
    setTimeout(() => {
      const element = document.getElementById("Finance & HR");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the delay as needed
  };

  const handleButtonClickDev = ({ }) => {
    setActiveTab(3); // Assuming Marketing tab is the second tab (index 3)
    setTimeout(() => {
      const element = document.getElementById("Dev");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the delay as needed
  };

  const [showBio, setShowBio] = useState(null);

  const handleToggle = (index) => {
    setShowBio(showBio === index ? null : index);
  };

  return (
    <LayoutNew>
      <Seo
        title={doc.data.team_page_title}
        description={doc.data.team_page_short_description}
        image={imgSrc}
      />
      <div className="relative -z-10 pt-14 xl:pt-20" style={{ display: "grid", background: "#000000" }}>
        <StyledGatsbyImage
          alt="hero background"
          image={imgHero}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          {doc.data.show_page_title
            ?
            <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2">
              <div className="flex flex-col justify-center">
                <h2 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-4">
                  {doc.data.team_page_title}
                </h2>                
              </div>
            </div>
            :
            null
          }
        </div>
      </div>

      {doc.data.red_bubble_text
        ?
        <div>
          <div className="w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-red-800 mx-auto py-12 px-6 md:px-16 -mt-8 md:-mt-16 z-20 rounded-3xl shadow-lg">
            <h2 className="text-white font-medium text-center text-lg md:text-xl">{doc.data.red_bubble_text}</h2>
          </div>
        </div>
        :
        null
      }

      {doc.data.team_bio_section.map((item, index) => {
        const teamPhoto = getImage(item.photo)
        const backgroundColorClass = index % 2 === 0 ? "bg-white" : "bg-gray-100";
        return (
          <div className={`${backgroundColorClass} pt-12 md:pt-24 pb-12 md:pb-24`} key={index}>
            <div className="relative w-11/12 max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto xl:space-x-6">
              <div className="w-full xl:w-4/12 flex justify-center">
                <GatsbyImage
                  image={teamPhoto ? teamPhoto : photoPlaceholder}
                  alt={item.photo.alt ? item.photo.alt : item.name}
                  className="rounded-3xl shadow-lg"
                />
              </div>
              <div className="w-full xl:w-8/12">
                <div className="mt-6 xl:mt-0 mb-2">
                  <h2 className="text-site-red text-xl md:text-2xl uppercase font-extrabold text-center tracking-wider">{item.name ? item.name : "Team Name"}</h2>
                </div>
                <div className="lg:w-8/12 mx-auto border-t-2 border-site-gray-dark pt-2 mb-8">
                  <h3 className="text-site-gray-dark text-sm md:text-base uppercase font-bold text-center tracking-wider">{item.position ? item.position : "Position"}</h3>
                </div>

                <p className={pStyle}>{item.short_bio ? item.short_bio : "Short bio should go here."}</p>
                
                {item.with_view_team_button
                  ?
                  null
                  :
                  <div className="flex items-center justify-center mt-4">
                    <Link to="/contact">
                      <button className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-6 py-2 mb-6">Send an Email</button>
                    </Link>
                  </div>                  
                }

                {item.with_view_team_button && item.team_category === "Sales"
                  ?
                  <div className="flex flex-col md:flex-row items-center md:justify-center md:space-x-4 mt-4">
                    <Link to="/contact">
                      <button className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6">Send an Email</button>
                    </Link>
                    
                    <button className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6" onClick={handleButtonClickFinanceHR}>View Sales Team</button>
                  </div>
                  :
                  null
                }                
                
                {item.with_view_team_button && item.team_category === "Marketing"
                  ?
                  <div className="flex flex-col md:flex-row items-center md:justify-center md:space-x-4 mt-4">
                    <Link to="/contact">
                      <button className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6">Send an Email</button>
                    </Link>
                    
                    <button className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6" onClick={handleButtonClickFinanceHR}>View Marketing Team</button>
                  </div>
                  :
                  null
                }

                {item.with_view_team_button && item.team_category === "Operations"
                  ?
                  <div className="flex flex-col md:flex-row items-center md:justify-center md:space-x-4 mt-4">
                    <Link to="/contact">
                      <button className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6">Send an Email</button>
                    </Link>
                    
                    <button className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6" onClick={handleButtonClickFinanceHR}>View Operations Team</button>
                  </div>
                  :
                  null
                }

                {item.with_view_team_button && item.team_category === "Finance & HR"
                  ?
                  <div className="flex flex-col md:flex-row items-center md:justify-center md:space-x-4 mt-4">
                    <Link to="/contact">
                      <button className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6">Send an Email</button>
                    </Link>
                    
                    <button className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6" onClick={handleButtonClickFinanceHR}>View Finance & HR Team</button>
                  </div>
                  :
                  null
                }

                {item.with_view_team_button && item.team_category === "Dev"
                  ?
                  <div className="flex flex-col md:flex-row items-center md:justify-center md:space-x-4 mt-4">
                    <Link to="/contact">
                      <button className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6">Send an Email</button>
                    </Link>
                    
                    <button className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6" onClick={handleButtonClickFinanceHR}>View Dev Team</button>
                  </div>
                  :
                  null
                }
              </div>
            </div>
          </div>
        )
      })}

      <StyledDiv className="w-full pt-6 md:pt-24">
        {/* Tabs component with tab list and panels */}
        <Tabs selectedIndex={activeTab} onSelect={index => setActiveTab(index)}>
          <div className="w-full max-w-screen-lg mx-auto">
            <TabList>
              <Tab>Sales</Tab>
              <Tab>Marketing</Tab>
              <Tab>Operations</Tab>
              <Tab>Finance & HR</Tab>
              <Tab>Dev</Tab>
            </TabList>
          </div>

          <TabPanel>            
            <div id="Sales" className="pt-16 md:pt-24">
              <h2 className="text-site-red text-4xl md:text-6xl font-extrabold text-center">SRA Sales Team</h2>

              <div className="w-11/12 max-w-screen-xl grid grid-cols-1 xl:grid-cols-3 gap-4 mx-auto pb-16">
                {doc.data.team_bio_section_1.map((item, index) => {
                  const teamPhoto = getImage(item.photo);
                  return (
                    <div className="pt-12" key={index}>
                      <div className="relative flex flex-col items-center justify-center mx-auto">
                        <div className="w-full flex justify-center">
                          {showBio === index ? (
                            <div className="text-center p-4 bg-white rounded-3xl shadow-lg">
                              <p className={pStyle}>{item.short_bio ? item.short_bio : "Short bio should go here."}</p>
                            </div>
                          ) : (
                            <GatsbyImage
                              image={teamPhoto ? teamPhoto : photoPlaceholder}
                              alt={item.photo.alt ? item.photo.alt : item.name}
                              className="rounded-3xl shadow-lg"
                            />
                          )}
                        </div>
                        <div className="w-full">
                          <div className="mt-6 mb-2">
                            <h2 className="text-site-red text-xl md:text-2xl uppercase font-extrabold text-center tracking-wider">
                              {item.name ? item.name : "Team Name"}
                            </h2>
                          </div>
                          <div className="lg:w-full mx-auto border-t-2 border-site-gray-dark pt-2">
                            <h3 className="text-site-gray-dark text-sm md:text-base uppercase font-bold text-center tracking-wider">
                              {item.position ? item.position : "Position"}
                            </h3>
                          </div>
                          <div className="text-center mt-4">
                            <button
                              onClick={() => handleToggle(index)}
                              className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6"
                            >
                              {showBio === index ? "Close" : "Read Bio"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </TabPanel>

          <TabPanel>            
            <div id="Marketing" className="pt-16 md:pt-24">
              <h2 className="text-site-red text-4xl md:text-6xl font-extrabold text-center">SRA Marketing Team</h2>

              <div className="w-11/12 max-w-screen-xl grid grid-cols-1 xl:grid-cols-3 gap-4 mx-auto pb-16">
                {doc.data.team_bio_section_2.map((item, index) => {
                  const teamPhoto = getImage(item.photo);
                  return (
                    <div className="pt-12" key={index}>
                      <div className="relative flex flex-col items-center justify-center mx-auto">
                        <div className="w-full flex justify-center">
                          {showBio === index ? (
                            <div className="text-center p-4 bg-white rounded-3xl shadow-lg">
                              <p className={pStyle}>{item.short_bio ? item.short_bio : "Short bio should go here."}</p>
                            </div>
                          ) : (
                            <GatsbyImage
                              image={teamPhoto ? teamPhoto : photoPlaceholder}
                              alt={item.photo.alt ? item.photo.alt : item.name}
                              className="rounded-3xl shadow-lg"
                            />
                          )}
                        </div>
                        <div className="w-full">
                          <div className="mt-6 mb-2">
                            <h2 className="text-site-red text-xl md:text-2xl uppercase font-extrabold text-center tracking-wider">
                              {item.name ? item.name : "Team Name"}
                            </h2>
                          </div>
                          <div className="lg:w-full mx-auto border-t-2 border-site-gray-dark pt-2">
                            <h3 className="text-site-gray-dark text-sm md:text-base uppercase font-bold text-center tracking-wider">
                              {item.position ? item.position : "Position"}
                            </h3>
                          </div>
                          <div className="text-center mt-4">
                            <button
                              onClick={() => handleToggle(index)}
                              className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6"
                            >
                              {showBio === index ? "Close" : "Read Bio"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </TabPanel>

          <TabPanel>            
            <div id="Operations" className="pt-16 md:pt-24">
              <h2 className="text-site-red text-4xl md:text-6xl font-extrabold text-center">SRA Operations Team</h2>

              <div className="w-11/12 max-w-screen-xl grid grid-cols-1 xl:grid-cols-3 gap-4 mx-auto pb-16">
                {doc.data.team_bio_section_3.map((item, index) => {
                  const teamPhoto = getImage(item.photo);
                  return (
                    <div className="pt-12" key={index}>
                      <div className="relative flex flex-col items-center justify-center mx-auto">
                        <div className="w-full flex justify-center">
                          {showBio === index ? (
                            <div className="text-center p-4 bg-white rounded-3xl shadow-lg">
                              <p className={pStyle}>{item.short_bio ? item.short_bio : "Short bio should go here."}</p>
                            </div>
                          ) : (
                            <GatsbyImage
                              image={teamPhoto ? teamPhoto : photoPlaceholder}
                              alt={item.photo.alt ? item.photo.alt : item.name}
                              className="rounded-3xl shadow-lg"
                            />
                          )}
                        </div>
                        <div className="w-full">
                          <div className="mt-6 mb-2">
                            <h2 className="text-site-red text-xl md:text-2xl uppercase font-extrabold text-center tracking-wider">
                              {item.name ? item.name : "Team Name"}
                            </h2>
                          </div>
                          <div className="lg:w-full mx-auto border-t-2 border-site-gray-dark pt-2">
                            <h3 className="text-site-gray-dark text-sm md:text-base uppercase font-bold text-center tracking-wider">
                              {item.position ? item.position : "Position"}
                            </h3>
                          </div>
                          <div className="text-center mt-4">
                            <button
                              onClick={() => handleToggle(index)}
                              className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6"
                            >
                              {showBio === index ? "Close" : "Read Bio"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </TabPanel>

          <TabPanel>            
            <div id="Finance & HR" className="pt-16 md:pt-24">
              <h2 className="text-site-red text-4xl md:text-6xl font-extrabold text-center">SRA Finance & HR Team</h2>

              <div className="w-11/12 max-w-screen-xl grid grid-cols-1 xl:grid-cols-3 gap-4 mx-auto pb-16">
                {doc.data.team_bio_section_4.map((item, index) => {
                  const teamPhoto = getImage(item.photo);
                  return (
                    <div className="pt-12" key={index}>
                      <div className="relative flex flex-col items-center justify-center mx-auto">
                        <div className="w-full flex justify-center">
                          {showBio === index ? (
                            <div className="text-center p-4 bg-white rounded-3xl shadow-lg">
                              <p className={pStyle}>{item.short_bio ? item.short_bio : "Short bio should go here."}</p>
                            </div>
                          ) : (
                            <GatsbyImage
                              image={teamPhoto ? teamPhoto : photoPlaceholder}
                              alt={item.photo.alt ? item.photo.alt : item.name}
                              className="rounded-3xl shadow-lg"
                            />
                          )}
                        </div>
                        <div className="w-full">
                          <div className="mt-6 mb-2">
                            <h2 className="text-site-red text-xl md:text-2xl uppercase font-extrabold text-center tracking-wider">
                              {item.name ? item.name : "Team Name"}
                            </h2>
                          </div>
                          <div className="lg:w-full mx-auto border-t-2 border-site-gray-dark pt-2">
                            <h3 className="text-site-gray-dark text-sm md:text-base uppercase font-bold text-center tracking-wider">
                              {item.position ? item.position : "Position"}
                            </h3>
                          </div>
                          <div className="text-center mt-4">
                            <button
                              onClick={() => handleToggle(index)}
                              className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6"
                            >
                              {showBio === index ? "Close" : "Read Bio"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </TabPanel>

          <TabPanel>            
            <div id="Dev" className="pt-16 md:pt-24">
              <h2 className="text-site-red text-4xl md:text-6xl font-extrabold text-center">SRA Dev Team</h2>

              <div className="w-11/12 max-w-screen-xl grid grid-cols-1 xl:grid-cols-3 gap-4 mx-auto pb-16">
                {doc.data.team_bio_section_5.map((item, index) => {
                  const teamPhoto = getImage(item.photo);
                  return (
                    <div className="pt-12" key={index}>
                      <div className="relative flex flex-col items-center justify-center mx-auto">
                        <div className="w-full flex justify-center">
                          {showBio === index ? (
                            <div className="text-center p-4 bg-white rounded-3xl shadow-lg">
                              <p className={pStyle}>{item.short_bio ? item.short_bio : "Short bio should go here."}</p>
                            </div>
                          ) : (
                            <GatsbyImage
                              image={teamPhoto ? teamPhoto : photoPlaceholder}
                              alt={item.photo.alt ? item.photo.alt : item.name}
                              className="rounded-3xl shadow-lg"
                            />
                          )}
                        </div>
                        <div className="w-full">
                          <div className="mt-6 mb-2">
                            <h2 className="text-site-red text-xl md:text-2xl uppercase font-extrabold text-center tracking-wider">
                              {item.name ? item.name : "Team Name"}
                            </h2>
                          </div>
                          <div className="lg:w-full mx-auto border-t-2 border-site-gray-dark pt-2">
                            <h3 className="text-site-gray-dark text-sm md:text-base uppercase font-bold text-center tracking-wider">
                              {item.position ? item.position : "Position"}
                            </h3>
                          </div>
                          <div className="text-center mt-4">
                            <button
                              onClick={() => handleToggle(index)}
                              className="w-56 bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-2 py-2 mb-6"
                            >
                              {showBio === index ? "Close" : "Read Bio"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </StyledDiv>
    </LayoutNew>
  )
}

export default withPrismicPreview(PageTeamNew)